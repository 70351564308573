import * as React from "react"
import { Link, graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"

const ttt = "TITLE"
const ddd = "DESCRIPT"

// markup
const OnlinebarDetailPage = ({ data }) => {
  return (
    <Layout isHomePage={true} headerClass="bg-black" isLogoOneWhite={true} isLogoTwoWhite={true}>
      <Seo title={ttt} description={ddd} />
      <main id="online-bar-detail">
        <section id="online-kv-box">
          <div id="online-ttl">
            <h2>
              <StaticImage src="../images/online-ttl.png" alt="広島オンライン酒場" />
              <StaticImage id="online-stamp" src="../images/online-stamp.png" alt="第２・第４金曜 20:00〜" />
            </h2>
            <h3><StaticImage src="../images/online-subttl.png" alt="Visible Radio Connected TV" /></h3>
          </div>
        </section>
        <article class="inner">
          <section>
            <h1>♯1 広島の味 むすびのむさし</h1>
            <h2>今夜のお客様：むさし 山本伸一 ブロック長</h2>
            <StaticImage src="../images/onlinebar/1/img-1.jpg" alt="" />
            <div className="speaker">
              <span className="speaker-icon speaker-left"><StaticImage src="../images/speaker-1.png" alt="" width="50px" /></span>
              <p className="left-balloon">
                1958年創業のむすびむさし。全国各地で愛されるローカル弁当の1つ、むさしのお弁当。お花見にも、出張時にも、集まりにも、つい買ってしまう。店舗では、宴会も楽しいむさし。 ローカルＣＭで「む〜すびのむさしっ♪」と流れれば、広島県民に愛され続ける あの俵むすびの味が広島県民のお口に広がるのです。その俵むすびのはじまり。 むすびの美味しさのヒミツ。 「むさし」の店名の由来などなど、ちょいと詰めております。
              </p>
            </div>
            <p>
              <Link to="https://youtu.be/2pZkj8nsHlI">https://youtu.be/2pZkj8nsHlI</Link>
            </p>
            <hr />
            <StaticImage src="../images/onlinebar/1/img-2.jpg" alt="" />
            <div className="speaker">
              <span className="speaker-icon speaker-left"><StaticImage src="../images/speaker-1.png" alt="" width="50px" /></span>
              <p className="left-balloon">
                まずは、入社26年目大ベテランの山本さんのことを教えてください。どんなお仕事なんですか？
              </p>
            </div>

            <div className="speaker">
              <span className="speaker-icon speaker-right"><StaticImage src="../images/speaker-2.png" alt="" width="50px" /></span>
              <p className="right-balloon">
                店舗の管理、各店舗の味の確認、各店舗の商品開発他をしております。あとは、土日は厨房に入り、むすびをむすんでおります。
              </p>
            </div>
            <div className="speaker">
              <span className="speaker-icon speaker-left"><StaticImage src="../images/speaker-1.png" alt="" width="50px" /></span>
              <p className="left-balloon">
                むすびもむすばれているんですね！ところで、店舗によって人気商品は違ったりするんですか？
              </p>
            </div>

            <div className="speaker">
              <span className="speaker-icon speaker-right"><StaticImage src="../images/speaker-2.png" alt="" width="50px" /></span>
              <p className="right-balloon">
                店舗によって変わることもありますが。あの商品が全店舗ぶっちぎりの商品でございます！
              </p>
            </div>
            <p>
              <Link to="https://youtu.be/Dt7kBYnZ25c">https://youtu.be/Dt7kBYnZ25c</Link>
            </p>
            <hr />
            <StaticImage src="../images/onlinebar/1/img-3.jpg" alt="" />
            <div className="speaker">
              <span className="speaker-icon speaker-left"><StaticImage src="../images/speaker-1.png" alt="" width="50px" /></span>
              <p className="left-balloon">
                ところで山本さん、2013年に俵むすびが累計販売1億2千万個を突破したってほんとうですか？
              </p>
            </div>

            <div className="speaker">
              <span className="speaker-icon speaker-right"><StaticImage src="../images/speaker-2.png" alt="" width="50px" /></span>
              <p className="right-balloon">
                本当です。みなさまに愛していただきました。むすびを作った先代の想いが従業員に伝わり、毎日10回以上の試食で美味しさの追究を続けてこれたからこその数だと感じております。
              </p>
            </div>
            <div className="speaker">
              <span className="speaker-icon speaker-left"><StaticImage src="../images/speaker-1.png" alt="" width="50px" /></span>
              <p className="left-balloon">
                むすびのはじまり、そして「むさし」のはじまりを聞かせてください！
              </p>
            </div>
            <p>
              <Link to="https://youtu.be/pMOwq-JTmkM">https://youtu.be/pMOwq-JTmkM</Link>
            </p>
            <hr />
            <div className="speaker">
              <span className="speaker-icon speaker-left"><StaticImage src="../images/speaker-1.png" alt="" width="50px" /></span>
              <p className="left-balloon">
                広島のローカル弁当について、みなさまに知って欲しい！ということで、ワタクシ実食をしたいと思います。まずは、花篭むすび弁当の俵むすびから♪んー！この味！！この塩加減！もぐもぐ・・・続いて、若鶏なんかも！やはり、さっき言われてたように唐揚げは・・・もぐもぐ・・・
              </p>
            </div>

            <div className="speaker">
              <span className="speaker-icon speaker-right"><StaticImage src="../images/speaker-2.png" alt="" width="50px" /></span>
              <p className="right-balloon">
                ｗｗｗ
              </p>
            </div>
            <div className="speaker">
              <span className="speaker-icon speaker-left"><StaticImage src="../images/speaker-1.png" alt="" width="50px" /></span>
              <p className="left-balloon">
                ところで、むさしさんは東京に進出などはされないんでしょうか？
              </p>
            </div>

            <div className="speaker">
              <span className="speaker-icon speaker-right"><StaticImage src="../images/speaker-2.png" alt="" width="50px" /></span>
              <p className="right-balloon">
                ありがたいことに、お話はいただいております。
              </p>
            </div>
            <p>
              <Link to="https://youtu.be/x5BNfiMW_T4">https://youtu.be/x5BNfiMW_T4</Link>
            </p>
          </section>

        </article>
        <section className="about-this">
          <div className="inner">
            <h2>店舗情報</h2>
            <dl>
              <dt>店名</dt>
              <dd>むさし土橋店</dd>
              <dt>住所</dt>
              <dd>広島市中区袋町5-5-4F</dd>
              <dt>電話番号</dt>
              <dd>082-546-1178</dd>
            </dl>
          </div>
        </section>

        <Link className="link-btn" to="/onlinebar"><span>オンライン酒場一覧へ</span></Link>
      </main>

    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpNews(sort: { fields: [date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          uri
          title
          slug
          date(formatString: "Do MMMM")
        }
      }
    }
  }
`

export default OnlinebarDetailPage
